import { SEO } from 'components';
import React from 'react';

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="404" />

    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
);

export default NotFoundPage;
